
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import moment from 'moment';
import ApiService from '@/core/services/ApiService';
import { object } from 'yup/lib/locale';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-batch-create',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
      },
      load: {
        term: '',
        term_day: '',
        start_date: '',
        end_date: '',
        class_type: '',
        term_hour: '',
        term_session: '',
        week_session: '' as any,
        day: [],
        training_room: [],
        start_time: [],
        end_time: [],
        duration_hour: [],
        lead_trainer: [],
        associate_trainer: [],
        coordinator: [],
      },
      term: Array(),
      timeDiff: '' as any,
      details: {
        sequence_number: 0,
        batch_number: '' as any,
        training_location: '',
        training_capacity: '',
        start_date: '',
        end_date: '' as any,
        course_duration: '',
        total_training_days: '',
        training_session: '',
        total_training_hours: '',
        terms: '',
        dropout_below_perc: '',
      },

      entityInfos: [],
      course_Info: [],
      courseList: [],
      instituteList: [],
      tranches_info: [],
      districts: [],
      info: {
        term_no: [],
        term_day: [],
        term_month: [],
        term_session: [],
        term_hours: [],
        class_type: [],
        start_date: [],
        end_date: [],
      },
      trainerInfo: [],
      employeeInfo: [],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
    };
  },
  async created() {
    await this.getEntityInfos();
    await this.getTranches();
  },
  methods: {
    async formSubmit() {
      this.loading = true;
      let formData = new FormData();
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      for (var key in this.details) {
        formData.set(key, this.details[key]);
      }
      for (var key in this.batch) {
        formData.set(key, this.batch[key]);
      }

      for (let i = 0; i < this.term.length; i++) {
        let day = Object.assign({}, this.term[i].day);
        this.term[i].day = day;
        let training_room = Object.assign({}, this.term[i].training_room);
        this.term[i].training_room = training_room;
        let start_time = Object.assign({}, this.term[i].start_time);
        this.term[i].start_time = start_time;
        let end_time = Object.assign({}, this.term[i].end_time);
        this.term[i].end_time = end_time;
        let duration_hour = Object.assign({}, this.term[i].duration_hour);
        this.term[i].duration_hour = duration_hour;
        let lead_trainer = Object.assign({}, this.term[i].lead_trainer);
        this.term[i].lead_trainer = lead_trainer;
        // let co_ordinator = Object.assign({}, this.term[i].coordinator);
        // this.term[i].coordinator = co_ordinator;
        let associate_trainer = Object.assign(
          {},
          this.term[i].associate_trainer
        );
        this.term[i].associate_trainer = associate_trainer;
        // let class_type = Object.assign({}, this.term[i].class_type);
        this.term[i].class_type = this.term[i].class_type;
      }
      formData.set('term', JSON.stringify(this.term));

      ApiService.post('batch/save', formData)
        .then((response) => {
          this.loading = false;
          console.log(response);
          if (response?.data?.status == 'success') {
            Swal.fire({
              title: 'Success',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.batch = {
                entity_id: '',
                tranche_id: '',
                institute_id: '',
                course_id: '',
              };
              this.details = {
                sequence_number: 0,
                batch_number: '' as any,
                training_location: '',
                training_capacity: '',
                start_date: '',
                end_date: '' as any,
                course_duration: '',
                total_training_days: '',
                training_session: '',
                total_training_hours: '',
                terms: '',
                dropout_below_perc: '',
              };
              this.showCourseNotice = false;
              window.location.reload();
            });
          } else if (response.data.status == 'error') {
            Swal.fire({
              title: 'Error',
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch((response) => {
          //console.log(response);
          this.loading = false;
          Swal.fire({
            title: 'Unknown Error',
            html: 'please check all required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },
    async getEntityInfos() {
      let entity_id = this.batch.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          console.log(response);
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    generateEndDate(start_date, duration) {
      // var startDate = "20.03.2020";
      var newDate = moment(start_date).add(duration, 'months').calendar();
      this.details.end_date = moment(newDate).format();
      // console.log(newDate);
      //var futureMonthEnd = moment(futureMonth).endOf("month");
      //   let date=new Date(start_date);
      //   moment(date).format('YYYY-MM-DD');
      //  // console.log(date);
      //   //console.log();
      //   //console.log(date.toLocaleDateString()+3);
      //   let changedate=new Date(date.setMonth(date.getMonth()+duration));
      //
      // console.log(currentDate);
      //console.log(this.details.end_date);
    },
    generatetermEndDate(start_date, term_day, week_per_session, index) {
      console.log(start_date, term_day, week_per_session, index);
      let weekCount = term_day / week_per_session;
      let monthCount = Math.ceil(weekCount / 4);
      var newDate = moment(start_date).add(monthCount, 'months').calendar();
      this.term[index].end_date = moment(newDate).format();
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getLastBatch() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_LAST_BATCH_API +
          '?entity_id=' +
          entity_id +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          institute_info_id +
          '&course_info_id=' +
          this.batch.course_id
      )
        .then((response) => {
          this.loading = false;
          if (response.data.data) {
            this.details.sequence_number =
              response.data.data.batch_number + 1;
            this.details.batch_number =
              response.data.data.batch_number + 1;
          } else {
            this.details.sequence_number = 1;
            this.details.batch_number = 1;
          }
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          // console.log(response);
          this.courseList = response.data.data;
          this.courseInfoData = true;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    getDuration(endtime, starttime, index, i, session, hour, day) {
      console.log(
        'endtime' + endtime,
        'start' + starttime,
        'index' + index,
        'i' + i,
        'session' + session,
        'hour' + hour,
        'day' + day
      );
      if (endtime && starttime) {
        // var duration = moment(endtime, 'hh:mm A').diff(
        //   moment(starttime, 'hh:mm A'),
        //   'hours'
        // );

        // this.term[index].duration_hour[i] =
        //   typeof duration == 'number' && duration ? duration : '';
        // let durationCount = hour / session;

        // if (durationCount > this.term[index].duration_hour[i]) {
        //   Swal.fire({
        //     title: 'Duration Error',
        //     text: 'Class duration does not match with course setup',
        //     icon: 'error',
        //     buttonsStyling: false,
        //     confirmButtonText: 'Close',
        //     customClass: {
        //       confirmButton: 'btn btn-danger',
        //     },
        //   }).then(() => {
        //     this.term[index].end_time[i] = '';
        //     this.term[index].duration_hour[i] = '';
        //   });
        // }
        var durationInMinutes = moment(endtime, 'hh:mm A').diff(
          moment(starttime, 'hh:mm A'),
          'minutes'
        );
        console.log('duration in minutes' + durationInMinutes);

        var durationInHours = durationInMinutes / 60;
        console.log('duration in hour' + durationInHours);

        this.term[index].duration_hour[i] = durationInHours.toFixed(2);

        let durationCount = hour / session;

        if (durationCount > durationInHours) {
          Swal.fire({
            title: 'Duration Error',
            text: 'Class duration does not match with course setup',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          }).then(() => {
            this.term[index].end_time[i] = '';
            this.term[index].duration_hour[i] = '';
          });
        }
      }

      this.term[index].day.forEach((dy, inx) => {
        if (i != inx && day == dy) {
          this.term[index].start_time.forEach((tm, itx) => {
            if (i != itx && starttime == tm) {
              Swal.fire({
                title: 'Warning!',
                html: 'Day and time should be unqiue.',
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              }).then(() => {
                this.term[index].day[i] = '';
                this.term[index].start_time[i] = '';
              });
            }
          });
        }
      });
    },
    checkDuration(endtime, starttime, index, i, session, hour) {
      let durationCount = hour / session;

      if (durationCount > this.term[index].duration_hour[i]) {
        Swal.fire({
          title: 'Duration Error',
          text: 'Class duration does not match with course setup',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        }).then(() => {
          this.term[index].start_time[i] = '';
          this.term[index].end_time[i] = '';
          this.term[index].duration_hour[i] = '';
        });
      }
    },
    getDay(day, index, i) {
      this.term[index].day.forEach((dy, inx) => {
        if (i != inx && day == dy) {
          Swal.fire({
            title: 'Warning!',
            html: 'Day should be unqiue.',
            icon: 'warning',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          }).then(() => {
            this.term[index].day[i] = '';
          });
        }
      });
    },
    parseTime(cTime) {
      if (cTime == '') return null;
      var d = new Date();
      var time = cTime.match(/(\d+)(:(\d\d))?\s*(p?)/);
      d.setHours(
        parseInt(time[1]) + (parseInt(time[1]) < 12 && time[4] ? 12 : 0)
      );
      d.setMinutes(parseInt(time[3]) || 0);
      d.setSeconds(0, 0);
      return d;
    },
    async checkBatchEligibility() {
      this.showCourseNotice = false;
      this.loading = true;
      if (this.batch.course_id) {
        await ApiService.get(
          'batch/add_eligibility?tranche_id=' +
            this.batch.tranche_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&training_institute_id=' +
            this.batch.institute_id +
            '&course_info_id=' +
            this.batch.course_id
        )
          .then((response) => {
            if (response.data.status == 'error') {
              this.loading = false;
              Swal.fire({
                title: 'Warning!',
                html: response.data.message,
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-warning',
                },
              });
            } else {
              this.courseNotice(response.data.data);
            }
          })
          .catch((response) => {
            this.loading = false;
            console.log(response);
          });
      }
    },
    async courseNotice(batch_size = null) {
      this.loading = true;
      this.getLastBatch();
      this.getTrainerList();
      this.getEmployeeList();
      await ApiService.get('course/show/' + this.batch.course_id)
        .then((response) => {
          this.course_Info = response.data.data;
          this.details.course_duration = response.data.data?.month;

          this.details.total_training_days = response.data.data?.day;

          this.details.training_session = response.data.data?.session;

          this.details.total_training_hours = response.data.data?.hour;

          this.details.terms = response.data.data?.term;
          this.details.dropout_below_perc =
            response.data.data?.course_config_info?.dropout_below_perc;
          this.details.training_capacity = (batch_size !=  null) ? batch_size : response.data.data?.trainee_per_batch;

          for (let i = 0; i < parseInt(this.details?.terms); i++) {
            this.term.push(JSON.parse(JSON.stringify(this.load)));
          }
          for (let i = 0; i < response.data.data.course_term_info.length; i++) {
            this.term[i].term_day =
              response.data.data?.course_term_info[i].term_day;
            // if(response.data.data?.course_term_info[i].class_type=='1'){
            //   this.term[i].class_type = 'Class Work'
            // }
            // if(response.data.data?.course_term_info[i].class_type=='2'){
            //   this.term[i].class_type = 'Class Work'
            // }
            this.term[i].class_type =
              response.data.data?.course_term_info[i].class_type;
            this.term[i].week_session =
              response.data.data?.course_term_info[i].week_session;
            this.term[i].term_session =
              response.data.data?.course_term_info[i].term_session;
            this.term[i].term_hour =
              response.data.data?.course_term_info[i].term_hour;
          }
          this.loading = false;
          this.showCourseNotice = true;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTrainerList() {
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_TRAINER_LIST_API +
          '?tranche_id=' +
          this.batch.tranche_id +
          '&entity_info_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          this.batch.institute_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&active_status=1'
      )
        .then((response) => {
          this.loading = false;
          this.trainerInfo = response.data.data;
          console.log(response);
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getEmployeeList() {
      await ApiService.get(
        'entity/entity_employee?entity_id=' +
          this.batch.entity_id +
          '&active_status=1'
      )
        .then((response) => {
          this.employeeInfo = response.data.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },
  },
  setup() {
    const BatchSchema = Yup.object().shape({
      entity_id: Yup.string().required().label('Entity'),
      institue_id: Yup.string().required().label('Institute'),
      course_id: Yup.string().required().label('Course'),
      sequence_number: Yup.string().required().label('Sequence Number'),
      // start_date: Yup.date().required().label("Start Date"),
      // end_date: Yup.date()
      //   .min(Yup.ref("start_date"), "End date has to be more than start date")
      //   .required()
      //   .label("End Date"),
    });
    return {
      BatchSchema,
    };
  },
});
